
import KirbyLink from './kirbylink.vue'
import KirbyImage from './kirbyimage.vue'
import KirbyLinkImage from './kirbylinkimage.vue'
export default {
    name: "homepagetop",
    props:{
      content: Object
    }, 
    data(){
      return {
        isHovering: false
      }
    }, 
    components:{
      KirbyLink,
      KirbyLinkImage,
      KirbyImage
    },
    methods:{
      toggleControls(){ 

        let $video = this.$refs.video; 
          $video.setAttribute("controls", "controls") 
      }
    }
}
